.brand-ford {
    .fm-action-items-card {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        padding: 24px 16px;
        background: $banner-lightgrey-background;
        min-width: 320px;
        height: 100%;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $fm-text-grey;
        @include desktop {
            min-width: unset;
            width: 360px;
            max-width: 100%;
        }
        &__label {
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            &__recommended {
                border-radius: 4px;
                background: $fm-caution-subtle;
                @extend .fm-action-card___label-padding;
            }
            &__required {
                border-radius: 4px;
                background: $fm-danger-subtle;
                padding: 0 4px;
                @extend .fm-action-card___label-padding;
            }
        }
        &__title {
            font-weight: 700;
            font-family: $f1-font-bold;
            width: fit-content;
            margin-top: 16px;
        }
        &__description {
            width: fit-content;
            margin: 8px 0 24px 0;
        }
        &__cta {
            margin-top: auto;
            .fm-btn__text {
                font-weight: 700;
                font-family: $f1-font-bold;
                font-size: 14px;
                width: fit-content;
            }
        }
    }
}
.fm-action-card___label-padding {
    padding: 2px 4px;
    @include desktop {
        font-size: 16px;
        padding: 4px 12px;
    }
}
