.brand-ford {
    .fm-connected-tire-pressure {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-family: $f1-font-regular;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $darkgrey;
        height: fit-content;
        &__header {
            display: flex;
            justify-content: start;
            gap: 8px;
            &__icon {
                max-width: 16px;
                max-height: 16px;
            }
            &__title {
                margin-bottom: unset;
                font-weight: inherit;
            }
        }
        .fm-connected-tire-pressure__table {
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $fm-text-grey;
            max-width: 160px;
            &__cell {
                text-align: left;
                width: fit-content;
                & > span {
                    margin-right: 8px;
                    &.fm-threshold {
                        color: $fm-danger;
                    }
                }
            }
        }
    }
}
