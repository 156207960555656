@import '../../../styles/main.scss';
.brand-ford {
    .tls-icon-wrapper {
        display: flex;
        justify-content: center;
        .tls_tire {
            fill: $text-blue;
        }
        .tls_text_container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 12px;
            text-align: right;
            padding: 14px 0px;
            margin-right: 5px;
            color: $fds-color-md-gray;
        }

        .tls_text_container_right {
            text-align: left;
            margin-left: 5px;
        }
        .tls_warning {
            color: $fds-color-orange;
            fill: $fds-color-orange;
        }

        .tls_alert {
            color: $error-message-text;
            fill: $error-message-text;
        }
    }
}
