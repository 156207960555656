.brand-ford {
    .fm-drawer-container {
        position: fixed;
        z-index: 2200;
        background-color: $fds-color-white;
        height: 100vh;
        width: 100vw;
        overflow: auto;
        top: 0;
        right: 0;
        transform: translateX(100%);
        transition: 325ms transform ease-in-out;

        @include tablet {
            width: 600px;
        }

        &.open {
            transform: translateX(0);
        }

        .fm-drawer-slot-component {
            margin: 48px 16px;
            @include tablet {
                margin: 80px 56px;
            }
        }

        .fds-font--ford-icons__clear {
            position: fixed;
            top: 24px;
            right: 24px;
            --icon-size: 15px;
        }
    }
    #fm-drawer-backdrop {
        opacity: 0;
        transition: 325ms opacity cubic-bezier(0.42, 0, 0.58, 1);

        &.open {
            opacity: 1;
            z-index: 2100;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
}
