.brand-ford {
    .fm-progress-bar {
        position: relative;
        background-color: white;
        .progress-container {
            display: flex;
            align-items: center;
            border: 1px solid $fds-color-lt-gray-b;
            background-color: $white;
        }
        .bar-container {
            flex: 1;
            vertical-align: middle;
            box-sizing: border-box;
        }
        .filler {
            height: 90%;
            margin-top: 0.5px;
            vertical-align: middle;
            border-top: 1px solid $fds-color-lt-gray-b;
            border-bottom: 1px solid $fds-color-lt-gray-b;
        }
        .filler::-webkit-progress-bar {
            background-color: transparent;
        }
    }
}
