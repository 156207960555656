.brand-ford {
    .support-section {
        background-color: $fds-color-surface;
        .feature-trio {
            padding: 0px;
            margin: 0 5%;
            max-width: 100%;
            .feature-trio-title {
                color: $fds-color-dk-gray;
                font-family: $f1-font-light;
                font-size: 20px;
                line-height: 30px;
                text-align: left;
                padding-top: 60px;
                padding-bottom: 5px;
                border-bottom: 0.5px solid $fds-color-dk-gray;
                @include mobile {
                    padding-top: 80px;
                }
            }

            .feature-trio-subtitle {
                display: none; //These are not required so its intentionally hidden
            }

            .feature-trio-cards {
                margin-top: 40px;
                padding: 0 0 80px 0;
                display: flex;
                @include mobile {
                    padding-bottom: 40px;
                }
                @media (max-width: 991px) {
                    flex-direction: column;
                }
                .card-wrap {
                    margin-right: 24px;
                    flex-wrap: wrap;
                    width: calc(100% / 3);
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .card {
                        box-shadow: none;
                        padding: 0;
                        width: revert;
                        max-width: revert;
                        margin-right: 0;
                        .card-header {
                            border-radius: 3px;
                            height: 220px;
                        }
                        .card-title {
                            display: none; //This is not required so its intentionally hidden
                        }
                        .card-description {
                            @media (max-width: 991px) {
                                min-height: 34px;
                                margin-bottom: 25px;
                            }
                            font-family: $f1-font-light;
                            color: $fds-color-dk-gray;
                            font-size: 24px;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.42;
                            text-align: center;
                            min-height: 105px;
                            margin-top: 20px;
                            margin-bottom: 13px;
                        }
                        .card-body {
                            @include desktop {
                                padding: 0px;
                            }
                            min-height: 109px;
                            @media (max-width: 991px) {
                                padding-bottom: 25px;
                            }
                            .button-position {
                                bottom: auto;
                                position: relative;
                                a.primary-button {
                                    margin: auto;
                                    display: flex;
                                    width: fit-content;
                                    .button-label {
                                        font-family: $f1-font-regular;
                                        font-size: 18px;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: 0.72;
                                        text-align: center;
                                        margin: auto;
                                    }
                                }
                            }
                            .fds-chevron-wrap {
                                display: none;
                            }
                        }
                    }
                }
                .card-wrap:last-child {
                    margin-right: 0;
                }
                .card-wrap {
                    @media (max-width: 991px) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
