@import 'src/styles/main';
.brand-lincoln {
    .connected-vehicle {
        height: 131px;
        text-align: center;
        max-width: 200px;
        .connected-vehicle-heading {
            width: auto;
            color: $brand-dark-grey;
            font-family: $proxima-nova-regular;
            letter-spacing: 1px;
            line-height: 18px;
            text-align: center;
            font-size: 12px;
            padding-bottom: 8px;
        }
        .icon {
            width: 42px;
            height: 35px;
            margin: 9px;
        }
        .connected-vehicle-bar {
            margin-left: auto;
            margin-right: auto;
            box-sizing: border-box;
            height: 1px;
            width: 54px;
            border: 0.5px solid;
            &.none {
                color: $brand-dark-grey;
            }

            &.good {
                color: $good;
            }

            &.moderate {
                color: $moderate;
            }

            &.severe {
                color: $severe;
            }
            &.blue {
                color: $light-blue;
            }
        }
        .number {
            color: $brand-dark-grey;
            font-family: $proxima-nova-regular;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 29px;
            text-align: center;
        }
    }
}
