.brand-lincoln {
    .tabs-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .tab-container {
        .tab {
            width: 100%;
            font-family: $proxima-nova-semi-bold;
            font-size: pToR(16);
            line-height: 1;
            text-align: center;
            letter-spacing: 1px;
            background-color: transparent;
            color: $brand-secondary;
            padding-bottom: 20px;
            border: none;
            border-bottom: 1px solid $brand-gray3;
            margin: 20px;

            &:hover {
                border-bottom: 2px solid $brand-border;
                transform: none;
            }
        }

        &.blue-tabs .tab-active {
            border-bottom: 2px solid $brand-border;
        }

        .tab-icon {
            display: inline-block;
        }
    }
    .tab-container-dark {
        @extend .tab-container;
        .tab {
            color: $brand-dark-grey;
        }

        .tab-active {
            border-bottom-color: $brand-border;
        }
    }
}
