@import '../../../styles/main.scss';

.brand-lincoln {
    .check-box-spacing {
        margin: 0;
        display: flex;
        align-items: center;
        padding-top: 7px;

        .checkbox {
            width: 20px;
            height: 20px;
            border-radius: 0;
            margin: 0;
            padding-right: 7px;

            &:before {
                width: 20px;
                height: 20px;
                border-radius: 0;
                color: $brand-secondary;
                background-color: $fds-color-white;
                border: 2px solid $brand-secondary;
            }

            &:checked {
                &:before {
                    content: '\2714';
                    width: 20px;
                    height: 20px;
                    font-size: 16px;
                    border-color: $brand-border;
                    background-color: $brand-border;
                    color: $brand-white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0;
                }
            }
        }

        .checkbox-label {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            padding-left: 7px;
            width: fit-content;
        }
    }
}
