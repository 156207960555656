@import '../../../styles/main';
.brand-ford {
    .skinny-banner-section {
        position: relative;
        width: 100%;
        margin: 50px 0;
        .skinny-banner-link {
            .skinny-banner-image {
                position: relative;
                width: 100%;
                height: auto;
            }
        }
        .skinny-banner-tooltip {
            position: absolute;
            bottom: 24px;
            right: 24px;
        }
    }
    .floating-panel__wrapper {
        .floating-panel__content {
            min-height: 100vh;
            @media (min-width: 500px) {
                min-height: auto;
            }
        }
        .tooltip__dismiss-icon {
            width: 18px;
            height: 18px;
        }
    }
}
