.brand-ford {
    .tabs-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .tab-container {
        .tab {
            width: 100%;
            font-family: $f1-font-regular, sans-serif;
            font-size: pToR(16);
            line-height: 12px;
            text-align: center;
            background-color: transparent;
            color: white;
            text-transform: uppercase;
            padding-bottom: 20px;
            border: none;
            margin: 20px;
            &:hover {
                border-bottom: 1px solid;
                border-bottom-color: white;
            }
        }

        .tab-active {
            border-bottom: 3px solid;
            border-bottom-color: white;
            &:hover {
                border-bottom: 3px solid;
                border-bottom-color: white;
            }
        }

        .tab-icon {
            display: inline-block;
        }

        &.blue-tabs {
            .tab {
                color: $fds-color-primary;
            }
            .tab-active {
                border-bottom-color: $fds-color-primary;
            }
        }
    }
    .tab-container-dark {
        @extend .tab-container;
        .tab {
            color: $dark-blue;
            &:hover {
                border-bottom-color: $dark-blue;
            }
        }

        .tab-active {
            border-bottom-color: $dark-blue;
        }
    }
}
