.brand-lincoln {
    .support-section {
        background-color: $brand-while-smoke;
        .feature-trio {
            padding: 0px;
            margin: 0 5%;
            max-width: 100%;
            .feature-trio-title {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 20px;
                font-weight: 300;
                letter-spacing: 3px;
                line-height: 30px;
                text-align: left;
                padding: 60px 0px 5px 0px;
                border-bottom: 0.5px solid $brand-gray3;
                @include mobile {
                    padding-top: 80px;
                }
            }

            .feature-trio-subtitle {
                display: none; //These are not required so its intentionally hidden
            }

            .feature-trio-cards {
                margin-top: 40px;
                padding: 0 0 80px 0;
                @include mobile {
                    padding-bottom: 40px;
                }
                .card-wrap {
                    width: calc(100% / 3);
                    margin-right: 24px;
                    flex-wrap: wrap;
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .card {
                        box-shadow: none;
                        padding: 0;
                        width: revert;
                        margin-right: 0;
                        .card-header {
                            border-radius: 3px;
                            height: 220px;
                        }
                        .card-title {
                            display: none; //This is not required so its intentionally hidden
                        }
                        .card-description {
                            @media (max-width: 991px) {
                                min-height: 34px;
                                margin-bottom: 25px;
                            }
                            font-family: $proxima-nova-regular;
                            color: $brand-secondary;
                            font-size: 24px;
                            font-weight: 300;
                            line-height: 1.42;
                            letter-spacing: 3px;
                            min-height: 68px;
                        }
                        .card-body {
                            @include desktop {
                                padding: 30px;
                            }
                            min-height: 109px;
                            @media (max-width: 991px) {
                                padding-bottom: 25px;
                            }
                            .button-position {
                                @media (max-width: 991px) {
                                    bottom: auto;
                                    position: relative;
                                }
                                a.primary-button {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    color: $brand-secondary;
                                    text-decoration: none;
                                    font-size: 16px;
                                    font-family: $proxima-nova-semi-bold;
                                    transition: color 0.3s ease-in-out;
                                    border: none;
                                    background: transparent;
                                    padding: 0 20px;
                                    height: 50px;

                                    &:hover {
                                        border-bottom: none;
                                        cursor: pointer;
                                    }

                                    &:focus {
                                        outline: 1px solid $brand-secondary;
                                    }

                                    .button-label {
                                        border-bottom: 2px solid transparent;
                                        font-size: 16px;
                                        font-family: $proxima-nova-semi-bold;
                                        padding-bottom: 5px;
                                        letter-spacing: unset;
                                        &:hover {
                                            border-bottom: 2px solid
                                                $brand-border;
                                        }
                                    }
                                }
                            }
                            .fds-chevron-wrap {
                                display: inline-block;
                                padding-left: 8px;
                            }
                            .fds-chevron .fds-dot,
                            .fds-chevron .fds-arrow {
                                font-size: 12px;
                                font-weight: 600;
                                top: 2px;
                                position: relative;
                            }
                        }
                    }
                }
                .card-wrap:last-child {
                    margin-right: 0;
                }
                .card-wrap {
                    @media (max-width: 991px) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
