.brand-lincoln {
    .disclaimer-list-container {
        max-width: 1288px;
        padding: 0 24px;
        margin: 60px auto;
        color: $brand-secondary;
        font-family: $proxima-nova-regular;
        font-size: 0.6875rem;
        line-height: 0.81rem;
        @include desktop {
            margin: 100px auto;
        }
        .disclaimer-list {
            list-style: none;
            margin: 0;
            .disclaimer-list-item {
                margin-bottom: 20px;

                sup {
                    margin-right: 2px;
                }
            }
        }
    }
}
