.brand-ford {
    .floating-panel {
        text-transform: none;
        display: inline-block;
        position: relative;

        .floating-panel__button {
            position: relative;
            cursor: pointer;
            background-color: transparent;
            border: 0px;
        }
    }

    .floating-panel__wrapper {
        position: absolute;
        padding: 0;
        z-index: 2004;
        box-sizing: border-box;
        transition: none;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        @include mobile {
            width: -webkit-fill-available;
        }

        @media (min-width: 500px) {
            width: auto;
            max-width: 380px;
            min-width: 220px;
            padding: 0;
            height: auto;
            max-height: 300px;
        }

        .floating-panel__content {
            position: relative;
            text-align: left;
            background-color: $white;
            border: 1px solid $fds-color-dk-gray;
            border-radius: 3px;
            padding: 16px;
            color: $fds-color-dk-gray;
            font-size: 1rem;
            line-height: 1.625rem;
            font-family: $f1-font-regular;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1),
                0 32px 32px 0 rgba(0, 0, 0, 0.1),
                0 48px 48px 0 rgba(0, 0, 0, 0.15);
            height: auto;
            min-height: auto;
            @media (min-width: 500px) {
                width: auto;
                max-width: 380px;
                height: auto;
                .tooltip__inner-content {
                    overflow: auto;
                    max-height: 300px;
                }
            }
        }
    }

    .floating-panel__pointer {
        position: absolute;
        top: 24px;
        left: -6px;
        width: 24px;
        height: 24px;
        background: #ffffff;
        transform: rotate(45deg);
        border: 1px solid $fds-color-dk-gray;
        z-index: 2003;
        border-bottom-style: none;
        border-right-style: none;

        &.floating-panel__pointer--top {
            transform: rotate(225deg);
            top: unset;
            bottom: 26px;
        }
    }
}
