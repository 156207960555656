@import './../../../styles/main';
.brand-ford {
    .masthead-container {
        display: flex;
        flex-direction: column;
        .masthead-preimagine {
            position: relative;
            width: 100%;
            height: 350px;
            overflow: hidden;
            @include desktop {
                height: 400px;
            }
            picture {
                pointer-events: none;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .masthead-banner-text {
                position: absolute;
                bottom: 33px;
                left: 16px;
                color: $white;
                @include desktop {
                    bottom: 45px;
                    left: 56px;
                }
                .masthead-header {
                    margin: 0;
                    font-size: 40px;
                    @include desktop {
                        font-size: 54px;
                    }
                }
            }
        }
        .masthead-text-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 32px 0 48px 16px;
            width: 100%;
            background: white;
            @include desktop {
                padding: 64px 0 80px 56px;
            }

            .masthead__category-headline {
                font-size: 40px;
                font-style: normal;
                line-height: 48px;
                max-width: fit-content;
                color: $f1-text-blue;
                text-align: left;
                @include desktop {
                    line-height: 64px;
                    font-size: 54px;
                    padding-bottom: 4px;
                }
            }
            .fm-btn {
                margin-top: 32px;
            }

            .masthead-text-description {
                max-width: 358px;
                width: 100%;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                color: $f1-text-blue;
                @include desktop {
                    max-width: 895px;
                }
            }
            .home-sign-in-container {
                display: unset;
            }
            .masthead__cta {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2rem;
                color: blue;
                .primary-button {
                    font-size: 1.125rem;
                    line-height: 0.8125rem;
                    color: blue;
                }
            }
        }
    }
}
