@import '../../../styles/main';
.cdc-custom-combobox-container {
    position: relative;
    .cdc-custom-combobox-selection {
        background-color: white;
        border-radius: 8px;
        padding: 0px 0 0px 50px;
        cursor: pointer;
        position: relative;
        border: 1px solid $fds-color-lt-gray-b;
        resize: both;
        display: flex;

        .cdc-country-name-text {
            width: 90% !important;
            height: 52px;
            text-align: left;
            border: hidden;
            font-family: $f1-font-regular;
            font-size: 16px;
            cursor: pointer;
        }
        .cdc-country-name-text:focus {
            outline: none;
        }
    }
    .cdc-custom-combobox-selection img {
        position: absolute;
        top: 9px;
        left: 10px;
    }
    .cdc-custom-country-list-holder {
        border: 1px solid gray;
        border-radius: 3px;
        background-color: white;
        position: absolute;
        top: 99%;
        width: 100%;
        height: 250px;
        overflow: auto;
        .cdc-custom-combobox-country-item {
            padding: 15px 0 10px 50px;
            cursor: pointer;
            position: relative;
        }
        .cdc-custom-combobox-country-item img {
            position: absolute;
            top: 15px;
            left: 10px;
        }
        .cdc-active {
            background-color: #0001;
        }
        .cdc-custom-combobox-country-item:hover {
            background-color: #0001;
        }
    }
}
