@import '../../../styles/main';

.brand-ford {
    .accordion {
        width: 100%;
    }

    .accordion-section {
        position: relative;
        color: $dropdown-text;
        font-family: $f1-font-regular;
        width: 100%;
        text-align: center;

        @include tablet {
            width: initial;
            min-width: 17.5rem;
        }

        @include desktop {
            width: 100%;
        }
        .accordion-title__heading {
            margin-bottom: 0;
        }
        .accordion-title {
            padding: 15px 25px;
            box-sizing: border-box;
            text-align: center;
            background-color: $white;
            width: 100%;
            display: flex;
            justify-content: space-between;
            -webkit-appearance: none;
            align-items: center;
            cursor: pointer;
            font-family: $f1-font-regular;
            border: none;
            border-top: 1px solid $fds-color-overlay;
            outline: none;
            &:hover {
                cursor: pointer;
            }
            @include mobile {
                margin: 0;
                width: 100%;
            }
            &.border-bottom {
                border-bottom: 1px solid $fds-color-overlay;
            }
            .accordion-title__header {
                display: contents;
            }
        }
        .header-color {
            color: $fds-color-primary;
        }
        .panel {
            display: none;
            &.expanded {
                display: block;
            }
        }
    }
}
