.brand-lincoln {
    .heading-h2 {
        font-size: 48px;
        font-family: $proxima-nova-regular;
        font-weight: 300;
        letter-spacing: 3px;
        line-height: 58px;
        text-align: center;
        color: $brand-secondary;
    }
    .heading-h3 {
        color: $dropdown-text;
        font-family: $proxima-nova-regular;
        font-size: pToR(48);
        letter-spacing: 3px;
        line-height: pToR(58);
        font-weight: 300;
        text-align: center;
    }
}
