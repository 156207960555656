.brand-lincoln {
    .tooltip {
        text-transform: none;
        display: inline-block;
        position: relative;

        .tooltip__button {
            position: relative;
            cursor: pointer;
            background-color: transparent;
            border: 0px;
        }
    }

    .tooltip__header {
        min-height: 24px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tooltip__header-content {
            font-size: 16px;
            margin: 0;
        }
    }

    .tooltip__dismiss-button {
        position: absolute;
        top: 14px;
        right: 14px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        height: 14px;

        .tooltip__dismiss-icon {
            height: 14px;
            width: 14px;
        }
    }
}
