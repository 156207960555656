@import '../../../styles/main';

.disclaimer__tooltip {
    .tooltip__button {
        padding: 8px;
        margin: -8px;
    }
}

.disclaimer__tooltip-symbol {
    color: $fds-color-secondary;
    margin: 0 2px;
    border-bottom: 1px solid $fds-color-secondary;
    position: relative;
    top: -2px;
    line-height: 1rem;
    font-size: 0.8rem;
}
