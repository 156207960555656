.brand-ford {
    .fm-preferred-dealer-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        @include desktop {
            margin-top: 24px;
        }
        gap: 16px;
        .fm-preferred-dealer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: $f1-font-regular;
            color: $fds-color-black;
            font-weight: 400;
            &__icon {
                width: 16px;
                height: 16px;
                @include desktop {
                    width: 24px;
                    height: 24px;
                }
                margin-right: 8px;
            }
            &__name {
                font-size: 24px;
                line-height: 32px;
                font-weight: 700;
                margin-right: 24px;
            }
            &__phone {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
