.brand-ford {
    .fds-activity-indicator {
        margin-top: 32px;
        &.full-height {
            height: 100vh;
            padding-top: 10%;
        }
    }
    .fds-activity-indicator--circular:not(.fds--ie11) {
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;
        & .fds-activity-indicator__progress-wrapper {
            height: 81px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                content: '';
                display: block;
                border: 1px solid $text-gray;
                border-radius: 50%;
                width: 75px;
                height: 75px;
            }
        }

        & .fds-activity-indicator__progress-line {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .fds-activity-indicator__progress-line_svg {
            width: 81px;
            height: 81px;
            transform: rotate(-90deg);
        }

        & .osb-percentage {
            transform: none !important;
        }

        & .fds-activity-indicator__progress-line_line--indeterminate {
            stroke-dasharray: 38, 162;
            stroke-dashoffset: 0;
            stroke-width: 2px;
            animation: fds-activity-indicator__animation--circular--indeterminate
                3s linear infinite;
            stroke-linecap: round;
            stroke: $dark-blue;
        }

        @-webkit-keyframes fds-activity-indicator__animation--circular--indeterminate {
            0% {
                stroke-dashoffset: 200;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }

        @keyframes fds-activity-indicator__animation--circular--indeterminate {
            0% {
                stroke-dashoffset: 200;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }
    }
    .fds-activity-indicator__notification,
    .fds-activity-indicator__instructions {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
    .osb-loader-message {
        text-align: center;
        padding-top: 10px;
        width: 40%;
        color: #4d4d4d;
        font-size: 16px;
        font-family: $f1-font-regular;
        @include mobile {
            width: 70%;
        }
    }
}
