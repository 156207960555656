.brand-ford {
    .pagination-cx {
        margin-top: 36px;

        .pagination {
            margin-top: 40px;
            margin-bottom: 30px;
            .fds-pagination {
                display: flex;
                list-style: none;
                margin: 0 auto;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                .fds-pagination__item {
                    display: flex;
                }
                .fds-pagination__link {
                    padding: 0.8rem 1rem;
                    text-align: center;
                    align-self: center;
                    pointer-events: none;
                    color: $fds-color-dk-gray;
                    .pagination-of {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }

                .fds-pagination__prev,
                .fds-pagination__next {
                    color: $fds-color-primary;
                    font-size: 18px;
                    white-space: nowrap;
                    font-family: $f1-font-regular;
                    cursor: pointer;
                }

                .fds-pagination__disabled {
                    color: $fds-color-disabled-dark;
                    pointer-events: none;
                }
                .left {
                    position: relative;
                    top: 2px;
                }
                .fds-arrow {
                    line-height: unset;
                }
                span {
                    display: flex;
                }
            }
        }
        .pagination-cx-current-view {
            font-size: 16px;
            text-align: left;
            padding: 0 1rem;
            @include tablet {
                padding: 0px;
            }
        }
        .pagination-cx-button {
            margin: 0 1rem;
            padding: 0 1rem;
            &:before {
                border-radius: 2px;
            }
        }
        .pagination-cx-button.disabled {
            &::before {
                background-color: $fds-color-disabled-dark;
            }
            &:hover {
                &::before {
                    background-color: $fds-color-disabled-dark !important;
                }
            }
        }
    }
    .feature-trio .pagination-cx {
        .feature-trio-cards {
            margin-top: 16px;
        }
    }
}
