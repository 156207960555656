@import '../../../styles/main.scss';
.brand-lincoln {
    .popover {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3000;
        width: 100%;
        height: 100%;

        @include desktop;
        @include tablet {
            background-color: $brand-lds-color-overlay;
        }

        @include mobile {
            height: 100%;
            background-color: $brand-secondary;
        }

        &.hide {
            display: none;
        }

        .popover-container {
            background-color: $brand-secondary;
            padding: 12px;
            opacity: 1;

            @include desktop;
            @include tablet {
                border-radius: 2px;
                border: 1px solid $grey;
                max-width: 300px;
                max-height: 558px;
                -webkit-animation-name: animatetop;
                -webkit-animation-duration: 250ms;
                animation-name: animatetop;
                animation-duration: 250ms;
            }

            @include mobile {
                height: 100vh;
                width: 100%;
                padding: 20px;
                max-height: unset;
                box-sizing: border-box;
            }

            .popover-wrapper {
                position: relative;
            }

            .popover-close {
                content: url('../../../assets/close-white.svg');
                width: 14px;
                height: 14px;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                z-index: 3;

                @include mobile {
                    width: 24px;
                    height: 24px;
                    right: 15px;
                }
            }

            .popover-heading {
                color: $white;
                font-family: $proxima-nova-regular;
                font-size: 13px;
                letter-spacing: 1px;
                line-height: 26px;
                padding-right: 50px;
                margin-bottom: 8px;
            }

            .popover-content {
                max-height: 490px;
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;

                @include mobile {
                    max-height: 480px;
                    margin-right: 10px;
                    padding-right: 0;
                    padding-top: 15px;
                }

                &::-webkit-scrollbar {
                    background-color: $brand-scrollbar;
                    margin-right: 15px;
                    padding-bottom: 15px;
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $brand-scrollbar-thumb;
                }

                p {
                    color: $white;
                    padding-bottom: 10px;
                    font-family: $proxima-nova-regular;
                    font-size: 13px;
                    letter-spacing: 1px;
                    line-height: 26px;
                    padding-right: 24px;
                }
            }

            .delete-record-popover {
                max-height: 150px;

                @include mobile {
                    max-height: 400px;
                }
            }
        }
        .popover-container:before {
            border-top: 10px solid transparent;
            border-left: 10px solid $brand-secondary;
            border-bottom: 10px solid transparent;
            border-right: none;
        }
        .primary-button {
            font-family: $proxima-nova-regular;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 30px;
            color: $white;
            line-height: 1rem;
            border: none;
            box-shadow: 0 1rem 1rem 0 rgba($white, 0.1),
                0 2rem 2rem 0 rgba($white, 0.1);
            transition: transform 0.3s ease-in-out,
                background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
                border-color 0.3s ease-in-out;
            padding: 0 1.875rem;
            height: 2.5rem;
            text-decoration: underline;
            text-decoration-color: $brand-border;

            &:hover {
                transform: scale(1.07);
                cursor: pointer;
                text-decoration: none;
            }

            &.color-light {
                color: $dark-blue;
                background-color: $white;

                &.color-outline {
                    background-color: transparent;
                    color: $white;
                    border: 1px solid $white;
                    box-shadow: none;
                    font-size: pToR(18);
                }
            }

            &.color-dark {
                &.color-fill {
                    &:hover {
                        background-color: $brand-light-blue;
                    }
                }

                &.color-outline {
                    background-color: transparent;
                    color: $dark-blue;
                    border: 1px solid $dark-blue;
                    box-shadow: none;
                    font-size: pToR(18);
                }
            }

            &.no-shadow-transparent {
                box-shadow: none;
                background-color: transparent;
                color: $white;
            }

            .button-label {
                white-space: nowrap;
                margin-right: 0.974rem;
                letter-spacing: 0.064rem;
                font-size: 1.125rem;

                &.no-chevron {
                    margin-right: 0;
                }
            }
        }
    }
    .no-mask {
        @include desktop;
        @include tablet {
            background-color: $popover-background;
        }
    }
    .popover-rte {
        a {
            color: $white;
            text-decoration: underline;
            text-decoration-color: $brand-border;
        }
    }
    .popover-left-arrow {
        .popover-container::before {
            left: -20px;
            top: 80px;
            transform: rotate(180deg);

            @include mobile {
                display: none;
            }
        }

        .popover-container::after {
            left: -20px;
            top: 80px;
            transform: rotate(180deg);

            @include mobile {
                display: none;
            }
        }
    }

    .popover-right-arrow {
        .popover-container::before {
            left: 298px;
        }
    }

    .popover-bottom-arrow {
        .popover-container::before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 130px;
            top: 170px;
            width: 11px;
            border-top: 11px solid transparent;
            border-left: 10px solid $brand-secondary;
            border-bottom: 11px solid transparent;
            border-right: none;
            transform: rotate(90deg);

            @include mobile {
                display: none;
            }
        }
        .popover-container::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 130px;
            top: 170px;
            width: 11px;
            border-top: 11px solid transparent;
            border-left: 10px solid $brand-secondary;
            border-bottom: 11px solid transparent;
            border-right: none;
            transform: rotate(90deg);

            @include mobile {
                display: none;
            }
        }
    }
    .popover-service-history-odometer {
        @include desktop {
            .popover-container {
                top: 120px !important;
            }
        }
    }
    .popover-vehicle-vin-popover {
        @include mobile {
            width: 100vw;
        }
        @include desktop {
            .popover-container {
                max-height: 700px;
                .popover-heading,
                .popover-content p {
                    font-size: 16px !important;
                }
            }
            .popover-container::before {
                top: 300px;
            }
            .popover-container::after {
                top: 300px;
            }
        }
    }
    .popover-enter-your-vin-tooltip {
        @include tablet {
            &.popover {
                .popover-container::after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    left: 120px;
                    top: 170px;
                    width: 11px;
                    border-top: 11px solid transparent;
                    border-left: 10px solid $brand-secondary;
                    border-bottom: 11px solid transparent;
                    border-right: none;
                    transform: rotate(90deg);
                }
            }
            &.popover-left-arrow {
                .popover-container::before {
                    border-left: 10px solid $brand-secondary !important;
                    top: 128px;
                }
            }

            &.popover-right-arrow {
                margin-left: 7px;

                .popover-container::before {
                    left: 294px !important;
                    border-right: 10px solid $brand-secondary !important;
                }
            }
        }
    }

    .popover-delete-record-popover {
        @include desktop;
        @include tablet {
            background-color: $fds-delete-overlay;
        }
    }
}
