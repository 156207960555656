@import '../../../styles/main.scss';

.brand-lincoln {
    .input-label {
        font-family: $proxima-nova-regular;
        padding-top: 20px;
    }

    .input-field-container {
        box-sizing: border-box;
        height: pToR(60);
        position: relative;
        padding: 10px 0 10px 14px;
        border-bottom: 1px solid $brand-secondary;
        background-color: $brand-while-smoke;
        margin-top: 20px;
        max-width: 800px;
        @include mobile {
            text-align: left;
        }

        input.input-field {
            font-family: $proxima-nova-regular;
            font-size: 16px;
            color: $brand-secondary;
            background-color: $brand-while-smoke;
            position: absolute;
            padding: 0;
            top: 20px;
            height: min-content;
            max-width: 90%;
            border: none;
            letter-spacing: 0;
            @include mobile {
                top: 10px;
                display: block;
            }
        }

        label.input-label {
            margin: 0;
            font-family: $proxima-nova-regular;
            font-size: 13px;
            color: $brand-secondary;
            position: relative;
            top: -5px;
            left: 0;
            transform-origin: top left;
            padding: 0;
            z-index: 1;
            transition: 0.2s;
            display: inline;
            letter-spacing: 0;
        }

        input[value=''] + label {
            top: 10px;
            left: 0;
            font-size: 16px;
            display: inline;
        }

        &:focus-within {
            border-color: $brand-border;
            border-width: 2px;
            label.input-label {
                top: -5px;
                left: 0;
                font-size: 13px;
            }
        }
        input,
        input:focus,
        input:active {
            outline: none;
            border: none;
        }

        .error-icon {
            content: url('../../../assets/exclamation-mark-red.png');
            position: absolute;
            right: 15px;
            top: 20px;
            height: 20px;
        }
    }

    .input-field-container.error {
        .input-label {
            color: $brand-light-error;
        }
    }

    .input-field-error {
        + .input-label {
            color: $brand-light-error;
        }
    }
    .input-field-container.error {
        border-color: $brand-light-error;
    }

    .error-message {
        top: -1.8rem;
        color: $brand-light-error;
        font-family: $proxima-nova-regular;
        font-size: 0.75rem;
        letter-spacing: 1px;
        line-height: 1.313rem;
        white-space: nowrap;

        @include mobile {
            padding: 20px;
        }
    }
}
