@import '../../../styles/main.scss';

.fds-chevron {
    &.up {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    &.down {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
    }

    &.right {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }

    font-family: FdsFordIcons;

    .fds-dot,
    .fds-arrow {
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1.25rem;
        font-size: 1rem;
        letter-spacing: normal;
        font-feature-settings: normal;
    }

    .fds-dot {
        &::before {
            content: $fds-chevron-dot;
        }
    }
    .fds-arrow {
        &.filled {
            margin-left: -1rem;
            color: white;

            &::before {
                content: $fds-chevron-right;
            }
        }

        &.unfilled {
            &::before {
                content: $fds-chevron-right-unfilled;
            }
        }
    }
}

.fds-chevron-wrap {
    position: relative;
    display: flex;

    .fds-chevron-text {
        padding-right: 15px;
        font-size: 16px;
        font-family: $f1-font-regular;
        letter-spacing: 1.2px;

        @include mobile {
            display: none;
        }
    }
}
