.brand-lincoln {
    .radio-button-container {
        display: flex;
        padding: 24px;
        align-items: center;
        max-width: 250px;
        border-radius: 3px;
        border: 1px solid $eyebrow-background;
        background-color: $fds-color-white;
        margin-right: 16px;
        &.disabled {
            background-color: $disabled-on-dark;
        }
        .radio-button-input {
            height: 16px;
            width: 16px;
            color: $fds-color-primary;
            accent-color: $brand-border;
        }
        .radio-button-label {
            margin: 0 18px;
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            line-height: 26px;
            font-size: 16px;
            letter-spacing: 0px;
        }
        .radio-button-image {
            height: 24px;
        }
    }
}
