@import './../../../styles/main.scss';

.vin-input-field-container {
    text-align: left;
    min-width: 300px;
    input {
        width: 100%;
        border: 0.96px solid #6e6e6e;
        border-radius: 0.2rem;
        box-sizing: border-box;
        height: 52px;
        font-size: 0.927rem;
    }
    .input-label {
        .error-icon {
            height: 15px;

            width: 15px;
        }
    }
    .error-message {
        white-space: normal;
        color: red;
        @include mobile {
            padding: unset;
        }
    }
}
