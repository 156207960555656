.chevron-icon-container {
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: ease;

    .chevron-down {
        transform: rotate(270deg);
    }

    .chevron-up {
        transform: rotate(90deg);
    }

    .chevron-right {
        transform: rotate(180deg);
    }

    .chevron-left {
        transform: rotate(0deg);
    }
}
