@import '../../../styles/main.scss';
.brand-ford {
    .software-updates-drawer {
        .software-updates-title {
            color: $fds-text;
            font-family: $f1-font-regular, sans-serif;
            font-size: 32px;
            line-height: 48px;
            margin-bottom: 48px;
            @include desktop {
                font-size: 48px;
                line-height: 56px;
                margin-bottom: 64px;
            }
        }
        .software-updates-description {
            color: $fds-color-black;
            font-family: $f1-font-regular, sans-serif;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            a {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-bold, sans-serif;
                font-size: 14px;
                line-height: 16px;
                &:hover {
                    color: $fm-denim-blue;
                }
            }
            @include desktop {
                margin-bottom: 32px;
            }
        }
        .fm-btn {
            padding-left: 0;
        }
        hr {
            background: $fm-text-light-grey;
            box-shadow: none;
            margin: 48px 0;
            @include desktop {
                margin: 64px 0;
            }
        }
        .settings-not-updateinprogress {
            color: $fds-color-black;
            font-family: $f1-font-regular, sans-serif;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            @include desktop {
                margin-bottom: 32px;
            }
        }
        .date-not-updateinprogress {
            color: $fds-color-black;
            font-family: $f1-font-regular, sans-serif;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            @include desktop {
                margin-bottom: 32px;
            }
        }
        .top-padding {
            line-height: 24px;
        }
        .fm-update-notes-wrapper {
            .mmota-updates__header {
                color: $fds-color-black;
                font-family: $f1-font-regular, sans-serif;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 24px;
                @include desktop {
                    margin-bottom: 32px;
                }
            }
            .mmota-updates__notes {
                color: $fds-color-black;
                font-family: $f1-font-regular, sans-serif;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 24px;
                @include desktop {
                    margin-bottom: 32px;
                }
            }
        }
    }
}
