@import '../../../styles/main.scss';
.brand-ford {
    .linear-progress-wrapper {
        .lp-icon-container {
            display: flex;
            justify-content: center;
            .lp-icon {
                height: 44.28px;
                width: 72px;
            }
        }
        .linear-bar-wrapper {
            margin-top: 22px;
            display: flex;
            justify-content: center;
            .outer-bar {
                height: 10px;
                width: 200px;
                border-radius: 6px;
                background-color: $fds-color-white;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
                .inner-bar {
                    height: 6px;
                    border-radius: 6px 0 0 6px;
                    margin: 2px;
                    background-color: $fds-color-green;
                }
                .inner-bar-alert {
                    background-color: $error-message-text;
                }
                .inner-bar-warning {
                    background-color: $fds-color-orange;
                }
            }
        }
        .lp-description {
            color: $fds-color-md-gray;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-top: 8px;
        }
    }
}
