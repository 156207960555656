@import '../../../styles/main.scss';
@import './popover.ford.scss';
@import './popover.linclon.scss';
.no-scroll {
    height: 100%;
    overflow: hidden;
}

@-webkit-keyframes animatetop {
    from {
        top: 0;
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: 0;
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
