.brand-lincoln {
    .quick-links-card {
        display: flex;
        background-color: $brand-white;
        width: 220px;
        margin-left: 5px;
        flex-grow: 1;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);
        @include desktop {
            flex-grow: 0;
        }
        .icon {
            align-items: center;
            display: flex;
            margin: 10px;
            justify-content: center;
            height: 40px;
            width: 40px;
        }
        .secondary-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .secondary-button-link {
                padding-left: 0;
            }
            .quick-links-cta {
                font-size: 12px;
                word-break: break-word;
                @include desktop {
                    font-size: 14px;
                }
            }
        }
    }
}
