@import './autocomplete-ford.scss';

.brand-lincoln {
    .auto-complete-container {
        .auto-complete-label-container {
            .auto-complete-label {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
        }
        .autoCompleteText {
            border: none;
            border-bottom: 1px solid $brand-secondary;
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            background: #ecedee;
            input {
                width: 90%;
                border: none;
                background: #ecedee;
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            ul {
                border-top: 1px solid $brand-secondary;
                span {
                    font-family: $proxima-nova-regular;
                }
            }
            .ulList::-webkit-scrollbar {
                width: 3px;
            }

            .ulList::-webkit-scrollbar-thumb {
                width: 3px;
                background-color: $brand-secondary;
            }
            ::before {
                color: '';
            }
            li {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
            li:hover {
                background-color: rgba(128, 128, 128, 0.2);
            }
            button {
                width: 10% !important;
                background: #ecedee;
                color: $brand-secondary;
                // .fds-arrow:hover {
                //     color: $brand-border;
                // }
            }
        }
    }
}
