.brand-ford,
.brand-demo {
    .primary-button {
        font-family: $f1-font-bold;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: white;
        border: none;
        padding: 0 30px;
        min-height: 40px;
        text-decoration: none;
        position: relative;
        font-size: 14px;
        line-height: 20px;

        &::before {
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            background-color: $fm-skyview;
            border-radius: 20px;
            // box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
            //     0 2rem 2rem 0 rgba(black, 0.1);
            transition: transform 0.3s ease-in-out,
                background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
                border-color 0.3s ease-in-out;
        }
        &:hover {
            cursor: pointer;

            &::before {
                transform: scale(1.07);
            }
        }
        &.disabled {
            cursor: default;
            color: $grey;
            &::before {
                transform: none;
                background-color: $lightgrey;
            }
            &:hover {
                &::before {
                    background-color: lightgray !important;
                }
            }
        }
        &.color-light {
            color: $dark-blue;
            &::before {
                background-color: white;
            }

            &.color-outline {
                color: $white;
                &::before {
                    background-color: transparent;
                    border: 1px solid $white;
                    box-shadow: none;
                }
            }
        }
        &.color-dark {
            &.color-fill {
                &:hover {
                    &::before {
                        background-color: #1700f4;
                    }
                }
            }
            &.color-outline {
                color: $dark-blue;
                &::before {
                    background-color: transparent;
                    border: 1px solid $dark-blue;
                    box-shadow: none;
                }
            }
        }

        .button-label {
            position: relative;
            white-space: nowrap;
            margin-right: 15px;
            &.no-chevron {
                margin-right: 0;
            }
        }

        .chevron {
            height: 1rem;
            width: auto;
            padding-left: 0.938rem;
        }

        &.no-shadow-transparent {
            color: $dark-blue;
            &::before {
                box-shadow: none;
                background-color: transparent;
            }
        }

        &.load-more {
            font-family: $f1-font-light;
            display: flex;
            text-align: center;
            margin-top: 2.5%;
            color: $dark-blue !important;
            font-size: 1.25rem;
            &::before {
                background-color: transparent;
                box-shadow: none;
            }
        }

        &.blue-filled-chevron {
            font-family: $f1-font-light;
            display: flex;
            text-align: center;
            margin-top: 2.5%;
            color: $dark-blue !important;
            font-size: 1.25rem;
            &::before {
                background-color: transparent;
                box-shadow: none;
            }
        }
        .screen-reader-only {
            position: absolute;
            width: 1px;
            clip: rect(0 0 0 0);
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
