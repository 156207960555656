.brand-ford {
    .toggle-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
            font-size: 12px;
            color: $fds-color-md-gray;
        }
        .switch {
            position: relative;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            width: 82px;
            height: 40px;
        }

        .switch input {
            opacity: 0;
            width: 10px;
            height: 10px;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $fds-color-border-gray;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &:focus {
                box-shadow: 0 0 3px $fds-color-dk-gray;
                outline-offset: 1px;
            }
        }

        .slider:before {
            position: absolute;
            content: '';
            height: 32px;
            width: 32px;
            left: 4px;
            bottom: 4px;
            background-color: $fds-color-white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
        input {
            width: 10px;
            height: 10px;
        }
        input[checked] + .slider {
            background-color: $fds-color-secondary;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px $fds-color-secondary;
        }

        input[checked] + .slider:before {
            -webkit-transform: translateX(40px);
            -ms-transform: translateX(40px);
            transform: translateX(40px);
        }

        .slider {
            border-radius: 27px;
        }

        .slider:before {
            border-radius: 50%;
        }
    }
}
