.brand-lincoln {
    .secondary-button {
        display: inline-block;

        .secondary-button-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: $brand-secondary;
            text-decoration: none;
            font-size: 1rem;
            font-family: $proxima-nova-semi-bold;
            transition: color 0.3s ease-in-out;
            border: none;
            background: transparent;
            padding: 0 20px;
            height: 50px;
            .secondary-button-text {
                border-bottom: 2px solid transparent;
                letter-spacing: unset;
            }

            &:focus {
                outline: 1px solid $brand-secondary;
            }

            &:hover {
                cursor: pointer;
                .secondary-button-text {
                    border-bottom: 2px solid $brand-border;
                }
            }
            @include mobile {
                height: 20px;
            }
        }

        .fds-chevron {
            .fds-dot {
                &::before {
                    content: '';
                }
            }
            .fds-arrow {
                &.filled {
                    color: $brand-secondary;
                    margin-right: 8px;
                    font-size: 12px;
                    font-weight: 900;

                    &::before {
                        content: $fds-chevron-right-unfilled;
                    }
                }
            }
        }
    }
}
