.brand-lincoln {
    .toggle-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
            font-size: 12px;
            color: $fds-color-md-gray;
        }
        .switch {
            position: relative;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            width: 50px;
            height: 26px;
        }

        .switch input {
            opacity: 0;
            width: 10px;
            height: 10px;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $fds-color-border-gray;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            &:focus-visible {
                outline: 1px solid $brand-secondary;
                outline-offset: 3px;
            }
        }

        .slider:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 3px;
            background-color: $fds-color-white;
            -webkit-transition: 0.2s;
            transition: 0.2s;
        }
        input {
            width: 10px;
            height: 10px;
        }
        input[checked] + .slider {
            background-color: $brand-secondary;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px $brand-secondary;
        }

        input[checked] + .slider:before {
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
        }

        .slider {
            border-radius: 22px;
        }

        .slider:before {
            border-radius: 50%;
        }
    }
}
