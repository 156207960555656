@import '../../../styles/main.scss';
.auto-complete-container {
    .auto-complete-label-container {
        display: flex;
        justify-content: space-between;
        .auto-complete-label {
            opacity: 0.99;
            font-family: $f1-font-regular;
            font-size: 11px;
            line-height: 16px;
            color: #4d4d4d;
            margin-bottom: 10px;
        }
        .osb-error-icon {
            width: 20px;
            margin-bottom: 10px;
        }
    }
    .autoCompleteText {
        width: 100%;
        border: solid 1px #6e6e6e;
        border-radius: 3px;
        font-family: $f1-font-regular;
        font-size: 14px;
        color: #000000ba;
        background-color: #fff;
        position: relative;
        z-index: 100;
        input {
            width: 88%;
            height: 50px;
            border: none;
            padding: 10px 5px;
            box-sizing: border-box;
            outline: none;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            color: #4d4d4d;
        }
        ul {
            list-style-type: none;
            text-align: left;
            margin: 0;
            padding: 0;
            border-top: 1px solid #4d4d4d;
            span {
                font-size: 14px;
                padding-left: 5px;
                margin-top: 10px;
                display: inline-block;
                font-family: $f1-font-regular;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
            }
        }
        .ulList {
            max-height: 200px;
            overflow: hidden;
            overflow-y: auto;
        }
        .ulList::-webkit-scrollbar {
            width: 3px;
        }

        .ulList::-webkit-scrollbar-thumb {
            width: 3px;
            background-color: #4d4d4d;
        }
        ::before {
            color: '';
        }
        li {
            padding: 10px 5px;
            cursor: pointer;
            font-family: $f1-font-regular;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            color: #00095b;
        }
        li:hover {
            background-color: rgba(128, 128, 128, 0.2);
        }
        button {
            width: 42px;
            height: 50px;
            background-color: #ffffff;
            color: #4d4d4d;
            padding: 0;
            margin: 0;
            border: 0;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            cursor: pointer;
            float: right;
        }
        button:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
