.brand-ford {
    .modal {
        display: none;
        position: fixed;
        z-index: 10000;
        padding: 6rem 0;
        left: 0;
        top: 2rem;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.7);
        margin-top: -40px;
        @include desktop {
            width: 100%;
        }
    }
    .modal-content {
        max-width: 1288px;
        color: $dark-blue;
        position: relative;
        background-color: $brand-white;
        margin: 48px auto 96px auto;
        padding: 0;
        border: 1px solid #888;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        @include desktop {
            width: 50%;
        }
        .modal-header {
            margin-bottom: 4rem;
            text-align: center;
            padding: 2px 16px;
        }
        .modal-body {
            margin-top: 2rem;
            padding: 2px 16px;
        }
        .modal-footer {
            display: flex;
            margin: 2rem 0;
            padding: 2px 16px;
            justify-content: center;
            flex-direction: column;
            .modal-note {
                margin-bottom: 32px;
            }
            .modal-footer-buttons {
                display: flex;
                justify-content: center;
                text-align: center;
                @include tablet {
                    flex-direction: row;
                }
                .text-button {
                    margin-left: 24px;
                }
            }
            .osb-modal-footer-buttons {
                margin-top: 30px;
                display: flex;
                justify-content: center;
                text-align: center;
                @include tablet {
                    flex-direction: row;
                }
                .text-button {
                    margin-left: 24px;
                }
            }
            .secondary {
                color: $dark-blue;
                font-size: larger;
                margin-left: 1rem;
                text-decoration: none;
                font-family: $f1-font-regular;
            }
        }
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -300px;
            opacity: 0;
        }
        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0;
        }
        to {
            top: 0;
            opacity: 1;
        }
    }
    /* The Close Button */
    .modal-close {
        color: $dark-blue;
        float: right;
        font-size: 28px;
        border: none;
        width: 24px;
        height: 24px;
        margin-right: 16px;
        margin-top: 16px;
        background-color: $brand-white;
    }
    .modal-close:hover,
    .modal-close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
}
