.brand-lincoln {
    .carousel {
        margin: 0 auto;
        touch-action: pan-y;
        overflow: hidden;

        .carousel-wrap {
            margin-bottom: 40px;

            .carousel-wrap__items {
                display: inline-flex;
                align-items: stretch;
                flex-wrap: nowrap;
                flex-direction: row;

                .carousel-wrap__items-item {
                    margin-bottom: 24px;
                    margin-right: 18px;

                    @include desktop {
                        margin-bottom: 0;
                        margin-right: 24px;
                    }
                }
            }
        }
    }
    .carousel + .carousel-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 0 60px;

        .carousel-controls__button {
            width: 40px;
            height: 40px;
            padding: 0;
            margin: 0 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .carousel-controls__range {
            min-width: 60px;
            text-align: center;
        }
    }
}
