.brand-lincoln {
    .dropdown-container-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding-top: 25px;
        .label {
            color: $grey;
            font-size: 14px;
            position: absolute;
            top: 0;
        }
        .dropdown {
            position: relative;
            color: $dropdown-text;
            font-family: $proxima-nova-regular;
            width: 100%;
            text-align: left;

            @include tablet {
                width: initial;
                min-width: 17.5rem;
            }

            @include desktop {
                max-width: 100%;
            }

            .error-message {
                position: absolute;
                top: -1.8rem;
                color: $text-gray;
                font-size: 0.75rem;
                letter-spacing: 1px;
                line-height: pToR(12);
                white-space: nowrap;
                font-family: $proxima-nova-regular;
                padding-left: 0;

                @include tablet-portrait {
                    top: -3rem;
                }

                .error-icon {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    margin-left: 5px;
                }
            }
            .dropdown-error-message-show {
                display: block;
            }
            .dropdown-error-message-hide {
                display: none;
            }

            .dropdown-button {
                @include input-border();
                @include input-font();
                color: inherit;
                font-family: inherit;
                margin: 0;
                width: 100%;
                height: 40px;
                background-color: $brand-while-smoke;
                user-select: none;
                display: flex;
                align-items: center;
                border: none;
                border-radius: 8px;
                border-bottom: 1px solid $grey;

                &:hover {
                    cursor: pointer;
                }

                &.focused {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                }

                &:disabled {
                    color: $brand-white;
                    border: none;
                    background-color: $disabled-dropdown;
                    cursor: initial;
                }

                .dropdown-current-item {
                    box-sizing: border-box;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                .dropdown-current-item .fds-chevron {
                    line-height: 12px;

                    .fds-arrow {
                        -webkit-text-stroke-width: 1.3px;
                        font-size: 10px;
                    }
                }

                .dropdown-current-item > span {
                    display: inline-block;
                    margin-right: 0.5rem;
                }
            }

            .dropdown-items-panel {
                display: block;
                position: absolute;
                top: 48px;
                z-index: 98;
                background-color: $gray;
                min-width: 100%;
                border: 1px solid $fm-skyview;
                font-size: 14px;
                border-radius: 8px;
                overflow-y: auto;
                max-height: 300px;
                box-sizing: border-box;
                list-style: none;
                margin: 0;
                padding: 0;
                font-family: $proxima-nova-regular;
                color: $brand-secondary !important;
                .dropdown-item {
                    display: block;
                    box-sizing: border-box;
                    width: 100%;
                    margin: 0;
                    padding: 16px;
                    position: relative;

                    &:hover {
                        cursor: pointer;
                    }

                    &.selected-item {
                        background-color: $light-sky-blue;
                    }
                }
            }
        }
    }
}
