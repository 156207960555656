@import '../../../styles/main';

.accordion {
    position: relative;
    color: $dropdown-text;
    font-family: $f1-font-regular;
    width: 100%;
    text-align: center;

    @include tablet {
        width: initial;
        min-width: 17.5rem;
    }

    @include desktop {
        width: 100%;
    }

    .accordion-title {
        padding: 15px 25px;
        box-sizing: border-box;
        text-align: center;
        background-color: $white;
        width: 100%;
        display: flex;
        justify-content: space-between;
        -webkit-appearance: none;
        align-items: center;
        cursor: pointer;
        font-family: $f1-font-light;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        outline: none;
        &:hover {
            cursor: pointer;
        }
        @include mobile {
            margin: 0;
            width: 100%;
        }
    }
}
