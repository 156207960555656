@import '../../../styles/main.scss';

.input-label {
    font-family: $f1-font-regular;
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: $fds-color-dk-gray;
    padding: pToR(30) 0 pToR(20) 0;
}

.input-field {
    width: 15.5rem;
    height: pToR(45);
    font-family: $f1-font-regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    color: $fds-color-primary;
    padding: 0 pToR(16) 0;
}

.input-field-error {
    @extend .input-field;
    border-color: $error-text;
    border-radius: pToR(1);
}

.error-message {
    top: -1.8rem;
    color: $error-text;
    font-family: $f1-font-light;
    font-size: 0.75rem;
    line-height: 1.313rem;
    white-space: nowrap;
}

.error-icon {
    content: url('../../../assets/error-warning.svg');
    margin-left: pToR(12);
}

div.fds-style {
    position: relative;
    height: pToR(60);
    padding: 10px 0 10px 0;
    margin-top: 20px;
    @include mobile {
        text-align: left;
    }

    .input-field {
        position: absolute;
        top: 30px;
        border: 1px solid $fds-color-md-gray;
        box-sizing: border-box;
        height: 50px;

        @include mobile {
            top: 10px;
            display: block;

            &:focus-visible {
                border: 1px solid $fds-color-md-gray;
                outline: auto;
            }
        }
    }

    &.valid .input-field {
        border: 1px solid $fds-color-green;
    }

    .input-field-error {
        border-color: $error-text;
        border-radius: pToR(1);
    }

    .input-label {
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 1;
        transition: 0.2s;
        display: inline;
        top: -12px;
        left: 0;
        user-select: none;
    }

    input[value=''] + label {
        top: 33px;
        left: 15px;
        font-size: 16px;
        cursor: text;

        @include mobile {
            top: 36px;
            white-space: nowrap;
            display: inline-block;
            text-overflow: ellipsis;
            width: 90%;
            height: initial;
            overflow: hidden;
        }
    }

    .success-icon {
        width: 14px;
        height: 14px;
        display: inline-block;
        transition: 0.2s;
        position: relative;
        top: -10px;
        left: 5px;
    }

    .error-icon {
        margin-left: 5px;
        height: 16px;
        width: 16px;
        transition: 0.2s;
        position: relative;
        top: -10px;

        &.empty {
            position: absolute;
            top: 5px;
            left: 0;
        }
    }

    input[value=''] + img.error-icon {
        .error-icon {
            position: relative;
            top: -10px;
            left: 0;
        }
    }

    &:focus-within {
        border-color: $brand-border;
        label.input-label {
            top: -12px;
            left: 0;
            font-size: 12px;
            display: inline;
        }
        .error-icon {
            position: relative;
            top: -10px;
            left: 0;
        }
    }

    &.error {
        border-bottom: none;
    }
}

div.fds-style + div.error-message {
    position: relative;
    top: 20px;

    @include mobile {
        padding: 0;
    }
}

@import './input-field.lincoln.scss';
