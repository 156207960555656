.brand-lincoln,
.brand-lincolndemo {
    .primary-button {
        font-family: $proxima-nova-semi-bold;
        display: flex;
        background-color: $brand-secondary;
        border: none;
        color: $brand-white;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 0 20px;
        height: 50px;
        border-bottom: 2px solid transparent;

        &:hover {
            border-color: $brand-border;
            cursor: pointer;
        }
        &.disabled {
            background-color: $ghost-grey;
            cursor: default;
            &:hover {
                background-color: $ghost-grey;
                border-color: transparent;
                transform: none;
            }
        }
        &.color-dark {
            &:focus {
                outline: 1px solid $brand-secondary;
                outline-offset: 5px;
                border-bottom: 2px solid $brand-border;
            }
            &.color-outline {
                background-color: transparent;
                color: $brand-secondary;
                border: 1px solid $brand-secondary;
                font-size: pToR(18);

                &:hover {
                    border-color: $brand-border;
                }
            }
        }
        &.color-light {
            color: $brand-primary;
            background-color: $brand-white;

            &.color-outline {
                background-color: transparent;
                color: $white;
                border: 1px solid $brand-white;
                font-size: pToR(18);

                &:hover {
                    border-color: $brand-border;
                }
            }
        }

        .button-label {
            white-space: nowrap;
            margin-right: 16px;
            &.no-chevron {
                margin-right: 0;
            }
        }

        .fds-chevron .fds-dot,
        .fds-chevron .fds-arrow {
            font-size: 0.8rem;
        }
        .screen-reader-only {
            position: absolute;
            width: 1px;
            clip: rect(0 0 0 0);
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
