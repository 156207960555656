.brand-lincoln {
    .category-quiet-card {
        display: flex;
        text-align: left;
        font-size: 1rem;
        line-height: 1.2rem;
        font-family: $proxima-nova-regular;
        color: $brand-mild-gray2;
        margin: 0 auto 60px;
        flex-direction: column-reverse;
        max-width: 400px;

        @include desktop {
            max-width: 100%;
            margin-bottom: 100px;
            flex-direction: row;
        }

        &__image-content {
            @include desktop {
                flex-direction: row-reverse;

                &__content-wrap {
                    padding-right: 0;
                    padding-left: 88px;
                }

                &__image-wrap {
                    padding-left: 0;
                    padding-right: 12px;
                }
            }
        }

        &__content-wrap {
            box-sizing: border-box;
            font-family: $proxima-nova-regular;
            color: $brand-secondary;

            @include desktop {
                padding-right: 88px;
                width: 50%;
            }

            &__subtitle {
                line-height: 1.625rem;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 16px;
                padding-bottom: 5px;
                border-bottom: 1px solid $brand-secondary;
                font-size: pToR(16);
            }

            &__title {
                font-size: 2em;
                line-height: 2.625rem;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 12px;
            }

            &__body {
                line-height: 1.5rem;
                font-size: 1.125rem;
                margin-bottom: 36px;
            }

            &__cta {
                @include tablet {
                    display: flex;
                }
                &__button {
                    display: inline-flex;
                    letter-spacing: 0;
                    margin-bottom: 16px;
                    margin-right: 16px;
                }
                &__link {
                    @include tablet {
                        margin-right: 16px;
                    }
                }
            }
        }

        &__image-wrap {
            box-sizing: border-box;
            width: 100%;
            margin: 0 0 30px;

            &__mobile-image {
                min-height: 212px;
            }

            &__desktop-image {
                display: none;
                min-height: 340px;
            }

            @include desktop {
                padding-left: 12px;
                width: 50%;
                margin: 0;

                &__desktop-image {
                    display: block;
                }

                &__mobile-image {
                    display: none;
                }
            }

            &__mobile-image,
            &__desktop-image {
                background-size: cover;
                background-position: center;
            }
        }
    }
}
